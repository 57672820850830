<template>
  <div class="container">
    <div id="mainAuthBanner" class="auth-banner">
      <img
        src="~assets/native-design/person_on_phone.svg"
        class="art_authuser"
      >

      <div class="logo_group">
        <img src="~assets/tfwm-logo.svg" class="logo_icon">
      </div>
    </div>
    <div class="message">
      <span class="text-h6">
        The TFWM App is not accessible via web. Please sign in on the TFWM app on your mobile device, available from the Google Play Store and Apple App Store.
      </span>
    </div>
    <div class="button-container">
      <m-cta-button
        :label="$t('return_to_login')"
        class="button"
        @ctaClick="logout"
      >
        Return to login
      </m-cta-button>
    </div>
  </div>
</template>
<script>
import { MCtaButton } from 'components/'

export default {
  name: 'TfwmHolding',
  components: {
    MCtaButton
  },
  methods: {
    logout () {
      this.$router.push({ hash: '' })
      this.$router.push({ name: 'Logout' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.container
  position relative
  width 100vw
  height 100vh
  max-height 100vh
  overflow-x hidden
  overflow-y auto
.button-container
  display: flex
  justify-content: center

 .button
  width: 15rem

.message
  display grid
  place-items center
  padding 4rem

.auth-banner
  background-color #3C1053
  height 463.5px
  width 100vw
  mask-image url('~assets/native-design/squiggle.svg')
  mask-size 101vw 464px
  mask-position bottom center
  mask-repeat no-repeat
  mask-mode alpha
  transition-property mask, height
  transition-duration .5s
  transition-timing-function ease-out

  .art_authuser
    width 25rem
    position absolute
    left 20rem
    top 5rem
    transition opacity .5s ease-in-out

  .logo_group
    position absolute
    display grid
    grid-template-columns 1fr
    place-items center
    gap 2rem
    left 50%
    top 7rem
    transform translateX(-50%)

    transition top 0.5s ease-out
      .logo_icon, .logo_logo
        transition-property height, display
        transition-duration .5s
        transition-timing-function ease-out

@media (max-width 1590px)
  .art_authuser
    opacity 0

@media (max-width 1100px)
  .art_icon:nth-child(1)
    opacity 0

@media (max-width 768px)

  .auth-banner
    mask-size 302vw 364px
    mask-position bottom center
    height 300px
    transition-duration .25s

    .art_authuser, .art_icon
      display none

    .logo_group
      place-items center
      bottom unset
      top 1rem
      transform scale(.75) translateX(-66.6666667%) // Math is stupid
</style>
